<template>
  <v-row>
    <v-col cols="12" md="12">
      <v-row>
        <v-col cols="12" md="4">
          <v-card>
            <v-card-text>
              <div class="d-flex align-center justify-space-around">
                <v-icon size="50">mdi-account-multiple</v-icon>
                <div class="d-flex flex-column">
                  <h3 class="font-weight-bold">{{ stats.users.total }}</h3>
                  <h4>عدد المستخدمين</h4>
                  <div class="d-flex justify-space-between">
                    <small class="ml-1"
                      >فعال : <strong>{{ stats.users.active }}</strong></small
                    >
                    <small
                      >غير فعال :
                      <strong>{{ stats.users.inactive }}</strong></small
                    >
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data() {
    return {
      stats: {
        users: {
          total: 4,
          active: 3,
          inactive: 1,
        },
      },
    };
  },
};
</script>
