<template>
  <v-card elevation="2" color="background">
    <v-card-text class="flex-1">
      <stats-card />
    </v-card-text>
  </v-card>
</template>

<script>
import StatsCard from './home-components/StatsCard.vue';
export default {
  components: {
    StatsCard,
  },
  data() {
    return {};
  },

  created() {},
};
</script>

<style></style>
